import Constants from '../constants/action'
import { api } from '../utils/api'

export const clearMessage = id => ({
  type: Constants.NOTIFICATION_CLEAR_MESSAGE,
  id,
})

export const clearMessages = () => ({
  type: Constants.NOTIFICATION_CLEAR_MESSAGES,
})

export const readMessage = id => ({
  type: Constants.NOTIFICATION_READ_MESSAGE,
  id,
})

export const notificationCancelOrderRequest = orderRequestId => dispatch => {
  dispatch({
    type: Constants.TOGGLE_NOTIFICATION_ACTION_REQUEST,
  })
  api
    .request('post', `order_requests/${orderRequestId}/cancel`, dispatch)
    .then(() => {
      console.log('CANCELLED')
      dispatch({
        type: Constants.TOGGLE_NOTIFICATION_ACTION_REQUEST,
      })
    })
    .catch(() => {
      // catch it but just ignore any errors
    })
}

export const getNotifications = (page, perPage) => {
  const data = {}

  if (page) {
    data.page = page
  }

  if (perPage) {
    data.per_page = perPage
  }

  return dispatch => {
    dispatch({ type: Constants.SET_NOTIFICATIONS_FETCHING })
    // TODO: to be changed to b2b/notifications after
    // it is deployed to production
    api.get('b2b/order_request_events', dispatch, data).then(response => {
      dispatch({
        type: Constants.SET_NOTIFICATIONS,
        notifications: response.data.map(
          ({
            payload: { orderRequestId, orderRequestNid, ...payload } = {},
            ...rest
          }) => ({
            ...rest,
            payload: {
              ...payload,
              orderRequestId: orderRequestNid || orderRequestId,
            },
          })
        ),
        page,
      })
    })
  }
}
