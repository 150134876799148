import { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Constants from '../constants/action'
import { socket } from 'ggx-global/faye/faye.setup'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import {
  updateCreditBalance,
  subtractCreditBalance,
} from 'ggx-src/billing/duck/actions'
import { orderService } from 'ggx-src/van-booking/api/order-api.service'
import { apiService as deliveryApiService } from 'ggx-src/delivery/api/api.service'

const useLegacyNotification = () => {
  const subscribedToChannelRef = useRef(false)
  const subscribedToTransportRef = useRef(false)

  const dispatch = useDispatch()
  const userId = useSelector(state => state?.session?.currentUser?.id)

  const receiveMessage = useCallback(
    message => {
      if (message.orderRequestNid) {
        const { orderRequestId, orderRequestNid, ...data } = message
        if (typeof data.eventType !== 'undefined') {
          dispatch({
            type: Constants.NOTIFICATION_RECEIVE_MESSAGE,
            message: {
              ...data,
              orderRequestId: orderRequestNid || orderRequestId,
            },
          })
        }

        if (data.eventType === 'completed') {
          orderService.getOrder(orderRequestNid).then(order => {
            if (order.payment_method === 'prepaid_wallet') {
              dispatch(
                subtractCreditBalance(Number(data.orderRequestPrice) || 0)
              )
              setTimeout(() => dispatch(updateCreditBalance()), 10 * 1000)
            }
          })
        }
      } else if (message.deliveryOrderTid) {
        if (typeof message.deliveryOrderEventType !== 'undefined') {
          dispatch({
            type: Constants.NOTIFICATION_RECEIVE_MESSAGE,
            message: {
              orderRequestEventId: message.deliveryOrderTid,
              ...message,
            },
          })
        }

        if (message.deliveryOrderEventType === 'delivered') {
          deliveryApiService
            .getOrder(message.deliveryOrderUuid)
            .then(({ order }) => {
              if (order.payment.type === 'prepaid_wallet') {
                dispatch(
                  subtractCreditBalance(Number(order.payment.total) || 0)
                )
                setTimeout(() => dispatch(updateCreditBalance()), 10 * 1000)
              }
            })
        }
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (!subscribedToTransportRef.current) {
      socket.bind('transport:down', () => {
        dispatch({
          type: Constants.NOTIFICATION_CONNECTION_DOWN,
          error: new Error('Transport down.'),
        })
      })
      socket.bind('transport:up', () => {
        dispatch({
          type: Constants.NOTIFICATION_CONNECTION_UP,
        })
      })

      subscribedToTransportRef.current = true
    }
  }, [dispatch])

  useEffect(() => {
    if (userId && !subscribedToChannelRef.current) {
      const subscription = socket.subscribe(
        `/notification/private/${userId}`,
        receiveMessage
      )

      subscription.callback(() => {
        subscribedToChannelRef.current = true
        dispatch({
          type: Constants.NOTIFICATION_SUBSCRIBE_SUCCEEDED,
        })
      })

      subscription.errback(error => {
        dispatch({
          type: Constants.NOTIFICATION_SUBSCRIBE_FAILED,
          error,
        })
      })

      dispatch({
        type: Constants.NOTIFICATION_SUBSCRIBING,
        socket,
      })
      return () => subscription.unsubscribe()
    }
  }, [dispatch, userId, receiveMessage])
}

export { useLegacyNotification }
