const bonusHK = {
  van: [20, 30, 40, 50],
  mudou: [30, 50, 70, 90],
  mudou9: [30, 60, 90, 120],
}

const bonusSG = {
  van: [2, 6, 10, 14],
  motorcycle: [2, 4, 6, 8, 10],
  sedan: [2, 4, 6, 8, 10],
  lorry10: [5, 10, 15, 20],
  lorry14: [5, 10, 15, 20],
  lorry24: [5, 10, 15, 20],
}

const bonusVN = {
  motorcycle: [5000, 10000, 15000, 20000, 30000, 40000, 50000],
  tricycle: [5000, 10000, 15000, 20000, 30000, 40000, 50000],
  van500: [5000, 10000, 15000, 20000, 30000, 40000, 50000],
  van1000: [5000, 10000, 15000, 20000, 30000, 40000, 50000],
  truck500: [5000, 10000, 15000, 20000, 30000, 40000, 50000],
  truck1000: [5000, 10000, 15000, 20000, 30000, 40000, 50000],
  truck1500: [5000, 10000, 15000, 20000, 30000, 40000, 50000],
  truck2000: [5000, 10000, 15000, 20000, 30000, 40000, 50000],
}

const BONUS_VALUES = {
  HK: bonusHK,
  SG: bonusSG,
  VN: bonusVN,
}

const BONUS_RANGE = {
  HK: { bonusMinimum: 10, bonusMaximum: 500 },
  SG: { bonusMinimum: 2, bonusMaximum: 100 },
  VN: { bonusMinimum: 5000, bonusMaximum: 100000 },
}

export { BONUS_VALUES, BONUS_RANGE }
