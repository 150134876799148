import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { replace } from 'react-router-redux'
import _ from 'lodash'

import { socket } from 'ggx-global/faye/faye.setup'
import { ApplicationContainer } from 'ggx-global/global.styles'
import { fetchCoupon } from 'ggx-global/duck/actions'
import { userService } from 'ggx-global/api/api.service'
import { Navigation as NavigationV2 } from 'ggx-global/navigation/v2/navigation.component'
import NotificationCentre from 'ggx-global/notifications/list/list.component'
import { ModalContainer } from 'ggx-global/modal-container/modal-container.component'
import { FloatingActionButton } from 'ggx-global/floating-action-button/floating-action-button.component'
import { PopupNotifications } from 'ggx-global/popup-notifications/popup-notifications.component'

import { SPACING } from 'ggx-componentlibrary/design/spacing/spacing.constants'
import { ComponentPositionByRoute } from 'ggx-componentlibrary/components/component-position-by-route/component-position-by-route.component'

import { featureFlagService } from 'ggx-service/feature-flag/feature-flag'

import Constants from 'ggx-src/legacy/constants/action'
import Actions from 'ggx-src/legacy/actions/notice'
import SessionActions from 'ggx-src/legacy/actions/session'
import OrganizationActions from 'ggx-src/legacy/actions/organizations'
import { clearAuthToken } from 'ggx-src/legacy/views/helpers/auth/tokens'
import {
  StyledSuccessSnackbar,
  StyledErrorSnackbar,
  LoadingDialog,
  SessionDialog,
  SessionSwitchDialog,
  SuspendedOrganizationDialog,
  checkUserPermission,
  TNCConfirmDialog,
} from 'ggx-src/legacy/views/helpers'
import ChangedPasswordModal from 'ggx-src/legacy/views/helpers/ChangedPasswordModal'

import {
  payByMonthlySettlement,
  payByCreditCard,
} from 'ggx-src/van-booking/duck/selectors'
import { getFeatureFlagGogodelivery } from 'ggx-src/delivery/duck/selectors'
import { apiService } from 'ggx-src/billing/api/api.service'
import { setCreditBalance } from 'ggx-src/billing/duck/actions'
import { setSubscriptionInfo } from 'ggx-src/paid-subscription-plan/duck/actions'
import { getSavedCard, getPaymentInfo } from 'ggx-src/payment/duck/actions'

import { MainContainer, ContentWrapper } from './authenticated.styles'
import { getCountry } from 'ggx-service/locale/country.service'

export class AuthenticatedContainer extends Component {
  state = {
    isNavigationCollapsed: false,
    isNavigationHovered: false,
    isLoadingGetPrepaidBalance: false,
    showPasswordChangedModal: false,
    isFirstLoadingCoupons: true,
    latestTNCTimestamp: null,
    showTNCConfirmDialog: false,
  }

  setIsNavigationCollapsed = () => {
    this.setState({ isNavigationCollapsed: !this.state.isNavigationCollapsed })
  }

  setIsNavigationHovered = value => {
    this.setState({ isNavigationHovered: value })
  }

  logout = () => {
    const { dispatch, currentUser } = this.props

    return dispatch(SessionActions.logout(currentUser?.id))
  }

  // eslint-disable-next-line func-names
  goToCustomerServicePage = _.once(async function() {
    await this.logout()

    this.props.dispatch(replace('/customer-service'))
  })

  updateUserConsentTimestamp = async () => {
    const { dispatch } = this.props
    try {
      const {
        data: { user },
      } = await userService.updateConsentTimestamp()

      this.setState({
        showTNCConfirmDialog: false,
      })
      dispatch({
        type: Constants.UPDATE_CURRENT_USER,
        data: { ...user },
      })
    } catch (error) {
      console.error(error)
    }
  }

  async componentDidMount() {
    const {
      dispatch,
      payByCreditCard,
      payByMonthlySettlement,
      featureFlagGogodelivery,
      featureFlagDisableNewUsersVisitingInnerPages,
    } = this.props

    dispatch(OrganizationActions.getRegions())
    dispatch(setSubscriptionInfo())
    dispatch(getSavedCard())
    dispatch(getPaymentInfo())

    featureFlagService.triggerFeatureFlag('gogodelivery_ggb')
    featureFlagService.triggerFeatureFlag('homepage_access')
    featureFlagService.triggerFeatureFlag('delivery_hold_and_charge')
    featureFlagService.triggerFeatureFlag('prepaid_topup_bank_transfer_only')
    featureFlagService.triggerFeatureFlag('developer')
    featureFlagService.triggerFeatureFlag('delivery_internal_user')
    featureFlagService.triggerFeatureFlag('delivery_optional_field_floor')
    featureFlagService.triggerFeatureFlag('delivery_package_size_lwh')
    featureFlagService.triggerFeatureFlag('delivery_address_matching')
    featureFlagService.triggerFeatureFlag('order_detail_revamp')
    featureFlagService.triggerFeatureFlag('paid_subscription_plan')
    featureFlagService.triggerFeatureFlag('vn_van500_van1000') // to be removed in https://gogotech.atlassian.net/browse/BET-1903
    featureFlagService.triggerFeatureFlag('transport_tips')
    featureFlagService.triggerFeatureFlag('inventory')
    featureFlagService.triggerFeatureFlag('delivery_integration')
    featureFlagService.triggerFeatureFlag('onboarding')
    featureFlagService.triggerFeatureFlag('order_requests_api_revamp')
    featureFlagService.triggerFeatureFlag('next_day_delivery')
    featureFlagService.triggerFeatureFlag('shopify_integration')
    featureFlagService.triggerFeatureFlag('new_delivery_types_in_history')
    featureFlagService.triggerFeatureFlag('report_courier-t')
    featureFlagService.triggerFeatureFlag(
      'temp_vn_new_transport_additional_option'
    )
    featureFlagService.triggerFeatureFlag(
      'disable_new_users_visiting_inner_pages'
    )
    featureFlagService.triggerFeatureFlag('show_premium_service_label')
    featureFlagService.triggerFeatureFlag('enable_coupon_feature')
    featureFlagService.triggerFeatureFlag(
      'vn_show_update_consent_timestamp_dialog'
    )
    featureFlagService.triggerFeatureFlag('transport_epod_photo')
    featureFlagService.triggerFeatureFlag('enable_address_book')
    featureFlagService.triggerFeatureFlag('resizeable_drawers')

    if (payByCreditCard || payByMonthlySettlement || featureFlagGogodelivery) {
      this.setState({ isLoadingGetPrepaidBalance: true })

      try {
        const {
          credits_balance: credit,
          free_credits_balance: freeCredit,
          free_credits_earliest_expiry_date: freeCreditExpiryDate,
          unsettled_balance: unsettledBalance,
          opened_at,
        } = await apiService.getPrepaidBalance()

        if (featureFlagDisableNewUsersVisitingInnerPages) {
          const { CUT_OFF_TIME } = featureFlagService.getFeatureWithConfig(
            'disable_new_users_visiting_inner_pages'
          )
          const cutOffTimestamp = new Date(CUT_OFF_TIME).getTime()

          if (
            !isNaN(cutOffTimestamp) &&
            cutOffTimestamp <= new Date(opened_at).getTime()
          ) {
            await this.goToCustomerServicePage()
          }
        }

        dispatch(
          setCreditBalance({
            credit,
            freeCredit,
            freeCreditExpiryDate,
            unsettledBalance,
          })
        )
      } finally {
        this.setState({ isLoadingGetPrepaidBalance: false })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      currentUser,
      children,
      featureFlagEnableCouponFeature,
      featureFlagVnShowUpdateConsentTimestampDialog,
    } = this.props

    if (!currentUser) return

    const nextRoute = children.props.route
    let currentUserLevel = currentUser.level

    checkUserPermission({
      routeProps: nextRoute,
      currentUserLevel,
      failedCb: () => dispatch(replace('/errors/auth')),
    })

    if (this.props.currentUser?.id && !this.subscription) {
      this.subscription = socket.subscribe(
        `/notification/subscription/${this.props.currentUser?.id}`,
        ({ event_type, message }) => {
          if (event_type === 'user') {
            switch (message) {
              case 'force_logout':
                clearAuthToken()
                this.setState({ showPasswordChangedModal: true })
              default:
                break
            }
          }
        }
      )
    }

    if (featureFlagEnableCouponFeature && this.state.isFirstLoadingCoupons) {
      this.setState({ isFirstLoadingCoupons: false })
      dispatch(fetchCoupon())
    }

    if (featureFlagVnShowUpdateConsentTimestampDialog) {
      const { LATEST_TNC_TIMESTAMP } = featureFlagService.getFeatureWithConfig(
        'vn_show_update_consent_timestamp_dialog'
      )
      const now = new Date().getTime()
      const latestTNCTimestamp = new Date(LATEST_TNC_TIMESTAMP).getTime()
      const userConsentTime =
        new Date(currentUser.consent_timestamp).getTime() || 0

      if (
        this.state.latestTNCTimestamp === latestTNCTimestamp ||
        now < latestTNCTimestamp
      )
        return

      if (
        !userConsentTime ||
        (userConsentTime && userConsentTime < latestTNCTimestamp)
      ) {
        this.setState({
          showTNCConfirmDialog: true,
          latestTNCTimestamp: latestTNCTimestamp,
        })
      } else {
        this.setState({
          latestTNCTimestamp: latestTNCTimestamp,
        })
      }
    }
  }

  componentWillUnmount() {
    this.subscription?.unsubscribe()
  }

  _clearMessages = () => {
    this.props.dispatch(Actions.clearMessages())
  }

  render() {
    const {
      currentUser,
      children,
      notifications,
      openCentre,
      unauthorizedSession,
      sessionSwitch,
      unreadCount,
      errorMessage,
      successMessage,
      showLoading,
      regions,
      branches,
      location,
      organization,
      payByMonthlySettlement,
      payByCreditCard,
      featureFlagHomepageAccess,
    } = this.props
    const { showTNCConfirmDialog } = this.state
    const suspendedOrganization = organization && organization.suspended
    const creditCardUserWithAccess = payByCreditCard
    const showDeliveryBulkImport = !payByMonthlySettlement
    // NOTE: this will check if regions and branches are got
    //   from backend before proceeding
    const node =
      !regions || !branches || this.state.isLoadingGetPrepaidBalance ? (
        <LoadingDialog open />
      ) : (
        <MainContainer>
          <NavigationV2
            currentUser={currentUser}
            isNavigationCollapsed={this.state.isNavigationCollapsed}
            isNavigationHovered={this.state.isNavigationHovered}
            setIsNavigationCollapsed={this.setIsNavigationCollapsed}
            setIsNavigationHovered={this.setIsNavigationHovered}
            showHomepage={featureFlagHomepageAccess}
            showVanBookingMenu={
              payByMonthlySettlement || creditCardUserWithAccess
            }
            showTransportBulkImport={
              getCountry() === 'VN' ||
              (getCountry() === 'HK' && organization.id === 1890)
            }
            showBillingNavigation={creditCardUserWithAccess}
            showDeliveryBulkImport={showDeliveryBulkImport}
            activePath={location.pathname}
          />
          <ContentWrapper
            $isNavigationCollapsed={this.state.isNavigationCollapsed}
            data-testid="main-content"
          >
            {children}
            {
              <ComponentPositionByRoute
                defaultPosition={{ bottom: SPACING.S, right: SPACING.S }}
                positionList={[
                  {
                    route: /\/orders\/\d+/,
                    position: { bottom: SPACING.XL2, right: SPACING.S },
                  },
                  {
                    route: '/orders',
                    position: { bottom: SPACING.XL2, right: SPACING.S },
                  },
                  {
                    route: '/delivery/shopify-integration',
                    position: { bottom: SPACING.XL5, right: SPACING.S },
                  },
                ]}
              >
                <FloatingActionButton />
              </ComponentPositionByRoute>
            }
          </ContentWrapper>
        </MainContainer>
      )

    return (
      <ApplicationContainer $pathname={location?.pathname}>
        <PopupNotifications
          isNavigationCollapsed={this.state.isNavigationCollapsed}
          isNavigationHovered={this.state.isNavigationHovered}
        />
        <NotificationCentre
          messages={notifications}
          openCentre={openCentre}
          unreadCount={unreadCount}
        />
        {node}
        <SessionDialog open={unauthorizedSession || false} />
        <SessionSwitchDialog open={sessionSwitch || false} />
        <SuspendedOrganizationDialog open={suspendedOrganization || false} />
        <TNCConfirmDialog
          open={showTNCConfirmDialog}
          actions={{
            logout: this.logout,
            updateUserConsentTimestamp: this.updateUserConsentTimestamp,
          }}
        />

        <StyledSuccessSnackbar
          open={successMessage.length > 0}
          message={successMessage}
          onRequestClose={this._clearMessages}
        />

        <StyledErrorSnackbar
          open={errorMessage.length > 0}
          message={errorMessage}
          onRequestClose={this._clearMessages}
        />

        {this.state.showPasswordChangedModal && (
          <ChangedPasswordModal
            onModalClose={() => {
              this.props.dispatch(
                SessionActions.logoutByPasswordChanged(currentUser?.id)
              )
            }}
          />
        )}

        <LoadingDialog open={showLoading} />
        <ModalContainer />
      </ApplicationContainer>
    )
  }
}

AuthenticatedContainer.propTypes = {
  currentUser: PropTypes.object,
  dispatch: PropTypes.func,
  messages: PropTypes.array,
  notifications: PropTypes.array,
  children: PropTypes.node,
  openCentre: PropTypes.bool,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  unauthorizedSession: PropTypes.bool,
  unreadCount: PropTypes.number,
  regions: PropTypes.arrayOf(PropTypes.object),
  branches: PropTypes.arrayOf(PropTypes.object),
  showLoading: PropTypes.bool,
  location: PropTypes.object,
  organization: PropTypes.object,
  payByCreditCard: PropTypes.bool,
  featureFlagDeveloper: PropTypes.bool,
  featureFlagDisableNewUsersVisitingInnerPages: PropTypes.bool,
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  channel: state.session.channel,
  unauthorizedSession: state.session.unauthorizedSession,
  sessionSwitch: state.session.sessionSwitch,
  messages: state.notification.messages,
  notifications: state.notification.notifications,
  openCentre: state.header.openCentre,
  successMessage: state.notice.successMessage,
  errorMessage: state.notice.errorMessage,
  unreadCount: state.notification.unreadCount,
  regions: state.organization.regions,
  branches: state.organization.branches,
  showLoading: state.notice.showLoading,
  organization: state.organization.organization,
  featureFlagGogodelivery: getFeatureFlagGogodelivery(state),
  payByMonthlySettlement: payByMonthlySettlement(state),
  payByCreditCard: payByCreditCard(state),
  featureFlagHomepageAccess: state.featureFlagHomepageAccess,
  featureFlagDisableNewUsersVisitingInnerPages:
    state.featureFlagDisableNewUsersVisitingInnerPages,
  featureFlagEnableCouponFeature: state.featureFlagEnableCouponFeature,
  featureFlagVnShowUpdateConsentTimestampDialog:
    state.featureFlagVnShowUpdateConsentTimestampDialog,
})

const ConnectedAuthenticatedContainer = connect(mapStateToProps)(
  AuthenticatedContainer
)

export default props => <ConnectedAuthenticatedContainer {...props} />
