import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  Body,
  BodyBold,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

import PrintSVG from '../assets/print.svg'

import { RouteInfo } from './route-info/route-info.component'
import { PaymentMethod } from './payment-method/payment-method.component'
import {
  StyledHeading,
  Container,
  DriverNotesContainer,
  PrintButton,
  StyledNote,
} from './shipping-info.component.styles'
import { PriceBreakdown } from './price-breakdown/price-breakdown.component'
import { orderService } from '../../../van-booking/api/order-api.service'
import { getLocale } from 'ggx-src/legacy/utils/locale'

import { LoadingSpinner } from 'ggx-src/legacy/views/helpers/loading/spinner'
import { SPACING } from 'ggx-componentlibrary/design/spacing/spacing.constants'

const ShippingInfo = ({ order, orderContactInfo, breakdownResponse }) => {
  const [t] = useTranslation()

  const remarks = order.order_request_detail?.remark
  const NotesToDriver = (
    <DriverNotesContainer>
      <BodyBold noMargin style={{ marginBottom: '12px' }}>
        {t('common__notes_to_driver')}
      </BodyBold>
      <Body noMargin>{remarks}</Body>
    </DriverNotesContainer>
  )
  const internalNotes = order.order_request_extra?.internal_notes
  const MerchantOrderNote = (
    <DriverNotesContainer>
      <BodyBold noMargin style={{ marginBottom: '12px' }}>
        {t('merchant_order_note')}
      </BodyBold>
      <Body noMargin>{internalNotes}</Body>
    </DriverNotesContainer>
  )

  const [printing, setPrinting] = useState(false)
  const iframeRef = useRef()
  const handlePrint = async () => {
    if (iframeRef.current) {
      setPrinting(true)
      const { data } = await orderService.downloadOrderPDF(
        order.id,
        getLocale() === 'zh-hk' ? 'zh_hk' : 'en'
      )
      const blob = new Blob([data], { type: 'application/pdf' })
      const fileURL = window.URL.createObjectURL(blob)
      const timeId = setTimeout(() => setPrinting(false), 10000)
      iframeRef.current.onload = function() {
        this.contentWindow.print()
        setPrinting(false)
        clearTimeout(timeId)
      }
      iframeRef.current.src = fileURL
    }
  }

  return (
    <div>
      <BodyBold style={{ marginBottom: '12px' }}>{t('common__route')}</BodyBold>
      <RouteInfo
        waypoints={order.waypoints}
        pickupTime={order.pickup_time}
        orderContactInfo={orderContactInfo}
      />
      {remarks && NotesToDriver}
      {internalNotes && MerchantOrderNote}
    </div>
  )
}

ShippingInfo.propTypes = {
  order: PropTypes.shape({
    waypoints: PropTypes.arrayOf(PropTypes.object),
  }),
  breakdownResponse: PropTypes.object,
}

ShippingInfo.defaultProps = {
  order: {},
}

export { ShippingInfo }
