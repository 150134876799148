import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { featureConfig } from 'ggx-src/legacy/utils/config'

import { BodyBold } from 'ggx-componentlibrary/design/typography/typography.styles'

import { getCountry } from 'ggx-service/locale/country.service'
import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'

import {
  Label,
  SectionTitle,
  PlaceOrderRow,
  BulkOrderRow,
  OrderManagementRow,
  TransactionHistoryRow,
  PlaceOrderIcon,
  BulkOrderIcon,
  OrderManagementIcon,
  TransactionHistoryIcon,
} from '../navigation.component.styles'
import FavoriteDriverSVG from '../assets/favorite-driver.svg'
import { FavoriteDriverRow } from './van-booking.component.styles'

const VanBookingSection = ({
  activePath,
  isNavigationCollapsed,
  isNavigationHovered,
  showTransportBulkImport,
  showBillingNavigation,
  isWalletUser,
}) => {
  const [t] = useTranslation()
  const country = getCountry()
  const vanBookingOrderPath = '/van/order'

  const handleClick = () => {
    brazeClient.setCustomEvent(BRAZE_EVENT.TRANSPORT.NEW_ORDER)
  }

  return (
    <>
      <SectionTitle
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <BodyBold>
          {country === 'SG'
            ? t('sidemenu.van.title')
            : t('common__van_truck_service')}
        </BodyBold>
      </SectionTitle>
      <PlaceOrderRow
        to={vanBookingOrderPath}
        $isActive={activePath === '/van/order'}
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
        onClick={handleClick}
      >
        <PlaceOrderIcon $isActive={activePath === '/van/order'} />
        <Label>{t('common__place_order_title')}</Label>
      </PlaceOrderRow>
      {showTransportBulkImport && (
        <BulkOrderRow
          to="/van/bulk-import"
          $isActive={activePath === '/van/bulk-import'}
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
        >
          <BulkOrderIcon $isActive={activePath === '/van/bulk-import'} />
          <Label>{t('common__bulk_order')}</Label>
        </BulkOrderRow>
      )}
      <OrderManagementRow
        to="/orders"
        $isActive={activePath?.startsWith('/orders')}
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
        data-testid="navigation-bar-van-order-management"
      >
        <OrderManagementIcon $isActive={activePath?.startsWith('/orders')} />
        <Label>{t('common__order_management')}</Label>
      </OrderManagementRow>

      <FavoriteDriverRow
        to="/van/favorite-drivers"
        $isActive={activePath === '/van/favorite-drivers'}
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <FavoriteDriverSVG />
        <Label>{t('common__favourite_drivers')}</Label>
      </FavoriteDriverRow>
      {featureConfig.WALLET && isWalletUser && !showBillingNavigation && (
        <TransactionHistoryRow
          to="/wallet"
          $isActive={activePath === '/wallet'}
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
          data-testid="navigation-bar-billing-and-balance"
        >
          <TransactionHistoryIcon $isActive={activePath === '/wallet'} />
          <Label>{t('sidemenu.van.item.billing')}</Label>
        </TransactionHistoryRow>
      )}
    </>
  )
}

VanBookingSection.propTypes = {
  activePath: PropTypes.string,
  isNavigationCollapsed: PropTypes.bool,
  isNavigationHovered: PropTypes.bool,
  showTransportBulkImport: PropTypes.bool,
  showBillingNavigation: PropTypes.bool,
  isWalletUser: PropTypes.bool,
}

export default VanBookingSection
