import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { reduxForm, Field, actions } from 'redux-form'

import NoticeActions from '../../../legacy/actions/notice'
import { apiService } from '../../api/api.service'
import {
  StyledBody,
  PaymentOptionHeading,
  CREDIT_CARD_GROUP_WIDTH,
} from './credit-card-section.component.styles'
import { PrepaidPackageForm } from './prepaid-package-form-component'
import { CreditCardGroup } from '../../components/credit-card-group.component'
import { SmallText } from 'ggx-componentlibrary/design/typography/typography.styles'
import getCountry from '../../../legacy/views/helpers/get_country'
import { CreditCardSection as MultipleCardSection } from 'ggx-src/van-booking/order/sidebar/order-form/summary/payment-method/payment-method-modal/credit-card-section'
import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'
import { getSavedCardInfo } from 'ggx-src/payment/duck/selectors'

const topupModalFormName = 'topup_modal_prepaid_package'
const { change } = actions

const MultipleCardSectionWrap = ({
  input,
  onChangeValue,
  onClickAdd,
  onClickEdit,
}) => {
  const primaryCardInfo = useSelector(state => getSavedCardInfo(state))

  useEffect(() => {
    onChangeValue(input.value || primaryCardInfo.id)
  }, [input.value])

  return (
    <MultipleCardSection
      selectedPaymentMethod={input.value || `${primaryCardInfo.id}`}
      handlePaymentSelection={input.onChange}
      onClickAdd={onClickAdd}
      onClickEdit={onClickEdit}
    />
  )
}

const CreditCardSection = ({
  dispatch,
  onChangeValue,
  onClickAdd,
  onClickEdit,
  initialTopupPackageId,
}) => {
  const [t] = useTranslation()
  const [topupPackages, setTopupPackages] = useState([])
  const country = getCountry()

  const fetchPrepaidPackages = async () => {
    try {
      const result = await apiService.getTopupPackages()
      setTopupPackages(result.data)
      dispatch(
        change(
          topupModalFormName,
          'topup_package_id',
          initialTopupPackageId ?? result.data[0].id
        )
      )
    } catch (error) {
      dispatch(NoticeActions.setErrorMessage(t('error.fetchtopup')))
    }
  }

  useEffect(() => {
    fetchPrepaidPackages()
  }, [])

  return (
    <form>
      <StyledBody>{t('billing.topup.modal.creditcard.title')}</StyledBody>
      <Field
        name="topup_package_id"
        packages={topupPackages}
        component={PrepaidPackageForm}
      />
      {getCountry() === 'HK' || getCountry() === 'SG' ? (
        <>
          <Body typographyStyles="mb-m" />
          <Field
            name="has_credit_card"
            component={MultipleCardSectionWrap}
            onChangeValue={onChangeValue}
            onClickAdd={onClickAdd}
            onClickEdit={onClickEdit}
            formWidth={CREDIT_CARD_GROUP_WIDTH}
            data-cy="credit-card-exist"
          />
        </>
      ) : (
        <>
          <StyledBody>{t('topup.creditcardTab.paymentOption')}</StyledBody>
          <PaymentOptionHeading>
            {t('topup.creditcardTab.option.creditCard')}
          </PaymentOptionHeading>
          <Field
            name="has_credit_card"
            component={CreditCardGroup}
            formWidth={CREDIT_CARD_GROUP_WIDTH}
            data-cy="credit-card-exist"
          />
        </>
      )}
      <SmallText typographyStyles="mt-M">
        {t('billing.topup.modal.bankTransfer.disclaimer')}
      </SmallText>
    </form>
  )
}

CreditCardSection.propTypes = {
  dispatch: PropTypes.func,
  onChangeValue: PropTypes.func,
  onClickAdd: PropTypes.func,
  onClickEdit: PropTypes.func,
  initialTopupPackageId: PropTypes.string,
}

const ReduxForm = reduxForm({
  form: topupModalFormName,
})(CreditCardSection)

const ConnectedCreditCardSectionReduxForm = connect()(ReduxForm)

export {
  CreditCardSection,
  ReduxForm,
  ConnectedCreditCardSectionReduxForm,
  topupModalFormName,
}
