import styled, { css } from "styled-components"

import { Body } from "../../../component-library/design/typography/typography.styles"
import { TextField } from "../../../component-library/components/text-field/text-field.component"
import { ClassicRadioButton } from "../../../component-library/components/classic-radio-button/classic-radio-button.component"
import { DisplayedRadioButton } from "../../../component-library/components/classic-radio-button/classic-radio-button.component.styles"

const StyledTextField = styled(TextField)``

const CustomBonusRadioButton = styled(ClassicRadioButton)`
  ${DisplayedRadioButton} {
    align-self: flex-start;
  }
`

const CustomAmountLabel = styled(Body)`
  margin-top: 0;
`

const overlayClass = css`
  z-index: 1200;
`;

export { StyledTextField, CustomBonusRadioButton, CustomAmountLabel, overlayClass }
