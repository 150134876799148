import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BodyBold } from 'ggx-componentlibrary/design/typography/typography.styles'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

import {
  Label,
  SectionTitle,
  TransactionHistoryRow,
  TransactionHistoryIcon,
} from '../navigation.component.styles'

import PaymentMethodSVG from '../assets/payment_method.svg'

import CouponRow from './coupon-row/coupon-row.component'
import { PaymentMethodRow } from './billing.component.styles'

const BillingSection = ({
  activePath,
  isNavigationCollapsed,
  isNavigationHovered,
  showBillingNavigation,
}) => {
  const [t] = useTranslation()
  const featureFlagEnableCouponFeature = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'enable_coupon_feature' })
  )

  if (!showBillingNavigation) {
    return (
      featureFlagEnableCouponFeature && (
        <>
          <SectionTitle
            $isNavigationCollapsed={isNavigationCollapsed}
            $isNavigationHovered={isNavigationHovered}
          >
            <BodyBold>{t('sidemenu.billing.title')}</BodyBold>
          </SectionTitle>
          <CouponRow
            activePath={activePath}
            isNavigationCollapsed={isNavigationCollapsed}
            isNavigationHovered={isNavigationHovered}
          />
        </>
      )
    )
  }

  return (
    <>
      <SectionTitle
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <BodyBold>{t('sidemenu.billing.title')}</BodyBold>
      </SectionTitle>

      <PaymentMethodRow
        to="/payment-method"
        $isActive={activePath === '/payment-method'}
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <PaymentMethodSVG />
        <Label>{t('common__payment_method')}</Label>
      </PaymentMethodRow>
      {featureFlagEnableCouponFeature && (
        <CouponRow
          activePath={activePath}
          isNavigationCollapsed={isNavigationCollapsed}
          isNavigationHovered={isNavigationHovered}
        />
      )}
      <TransactionHistoryRow
        to="/wallet"
        $isActive={activePath === '/billing-history'}
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
        data-testid="navigation-bar-transaction-history"
      >
        <TransactionHistoryIcon $isActive={activePath === '/billing-history'} />
        <Label>{t('common__transaction_history')}</Label>
      </TransactionHistoryRow>
    </>
  )
}

BillingSection.propTypes = {
  activePath: PropTypes.string,
  isNavigationCollapsed: PropTypes.bool,
  isNavigationHovered: PropTypes.bool,
  showBillingNavigation: PropTypes.bool,
}

export default BillingSection
