import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import pDebounce from 'p-debounce'

import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

import {
  OrderSearchContainer,
  StyledTextField,
  StyledButton,
} from './order-search.component.styles'

const OrderSearch = ({ value, handleClick, handleChange, onClearInput }) => {
  const [t] = useTranslation()
  const debouncedOrderSearch = pDebounce(handleClick, 300)

  const onChangeHandler = newValue => {
    if (newValue === '') {
      onClearInput()
    }
    handleChange(newValue)
  }

  const handleEnterPress = event => {
    if (value && event.key === 'Enter') {
      debouncedOrderSearch()
    }
  }

  return (
    <OrderSearchContainer>
      <StyledTextField
        type="text"
        placeholder={t('text_field__search__order_management')}
        value={value}
        onChange={onChangeHandler}
        showClearIcon
        onClearInput={onClearInput}
        onKeyPress={handleEnterPress}
      />
      <StyledButton onClick={handleClick} disabled={!value}>
        {t('common__search')}
      </StyledButton>
    </OrderSearchContainer>
  )
}

OrderSearch.propTypes = {
  value: PropTypes.string,
  handleClick: PropTypes.func,
  handleChange: PropTypes.func,
  onClearInput: PropTypes.func,
}

export { OrderSearch }
