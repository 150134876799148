import styled from 'styled-components'
import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'
import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const Link = styled(Body)`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-top: -4px;
  margin-left: -4px;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  transition: all 150ms ease;
  &:hover {
    background-color: ${COLOR.GREY_LIGHTER};
  }
`

export { Container, Link }
