import Route from 'react-router/lib/Route'
import IndexRedirect from 'react-router/lib/IndexRedirect'
import Redirect from 'react-router/lib/Redirect'
import IndexRoute from 'react-router/lib/IndexRoute'

import { getCountry } from 'ggx-service/locale/country.service'
import { featureFlagService } from './../service/feature-flag/feature-flag'
import { loadableCreator } from '../delivery/loading/loadable-creator'
import WalletTabbedViewContainer from '../legacy/containers/wallet/tabbed_view'
import Constants from '../legacy/constants/auth'
import Actions from '../legacy/actions/session'
import { payByMonthlySettlement, payByCreditCard } from './duck/selectors'

const LoadableOrder = loadableCreator({
  dynamicImport: () =>
    import(/* webpackChunkName: 'van-order' */ './order/order.component'),
  componentName: 'Order',
})

const LoadableOrderManagement = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'order-management' */ '../order-management/order-management.component'
    ),
  componentName: 'OrderManagement',
})

const LoadableTransactionHistory = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'billing-history' */ '../billing/transaction-history/transaction-history.component'
    ),
  componentName: 'TransactionHistory',
})

const LoadableVoucher = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'voucher' */ '../billing/voucher/voucher.component'
    ),
  componentName: 'Voucher',
})

const LoadablePaymentMethod = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'payment' */ '../billing/payment-method/payment-method.component'
    ),
  componentName: 'ConnectedPaymentMethod',
})

const LoadableFavoriteDrivers = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'favorite-drivers' */ './favorite-drivers/favorite-drivers.component'
    ),
  componentName: 'ConnectedFavoriteDrivers',
})

const LoadableBulkImport = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'bulk-import' */ './bulk-import/bulk-import.component'
    ),
  componentName: 'BulkImport',
})

const redirectToPlaceOrder = store => (_nextState, replace, callback) => {
  const { dispatch } = store
  const checkFlag = () => {
    const state = store.getState()
    if (payByMonthlySettlement(state)) {
      replace('/van/order')
    }
    callback()
  }

  Promise.all([dispatch(Actions.currentUser())])
    .then(checkFlag)
    .catch(checkFlag)
}

const legacyWalletRedirectionHandler = store => (
  _nextState,
  replace,
  callback
) => {
  const { dispatch } = store
  dispatch(Actions.currentUser()).finally(() => {
    if (payByCreditCard(store.getState())) {
      replace('/billing-history')
    }
    callback()
  })
}

const prepaidRoutesHandler = store => (_nextState, replace, callback) => {
  const { dispatch } = store
  const checkFlag = () => {
    const state = store.getState()
    const { featureFlagGogodelivery, featureFlagEnableCouponFeature } = state

    const isPayByMonthlySettlement = payByMonthlySettlement(state)

    if (
      isPayByMonthlySettlement &&
      !featureFlagGogodelivery &&
      !featureFlagEnableCouponFeature
    ) {
      replace('/404')
    }
    callback()
  }

  Promise.all([
    dispatch(Actions.currentUser()),
    featureFlagService.triggerFeatureFlag('gogodelivery_ggb'),
  ]).finally(checkFlag)
}

const bulkImportRouteHandler = store => (_nextState, replace, callback) => {
  const { dispatch } = store
  dispatch(Actions.currentUser()).finally(() => {
    const organization = store.getState().organization.organization
    if (
      !(
        getCountry() === 'VN' ||
        (getCountry() === 'HK' && organization.id === 1890)
      )
    )
      replace('/van/order')
    callback()
  })
}

const RevampVanBookingRoutes = store => (
  <>
    <Route path="/van">
      <IndexRedirect to="/van/order" />
      <Route path="order" component={LoadableOrder} />
      <Route path="favorite-drivers" component={LoadableFavoriteDrivers} />
      <Route
        path="bulk-import"
        component={LoadableBulkImport}
        onEnter={bulkImportRouteHandler(store)}
      />
    </Route>

    <Route path="/orders">
      <IndexRoute component={LoadableOrderManagement} />
      <Redirect path="view" to="/orders" />
      <Route path=":id" component={LoadableOrderManagement} />
    </Route>
  </>
)

const MonthlySettlementWalletRoutes = store => (
  <Route path="wallet" onEnter={legacyWalletRedirectionHandler(store)}>
    <IndexRoute
      component={WalletTabbedViewContainer}
      authorize={[Constants.B2B_MASTER_USER, Constants.B2B_BRANCH_USER]}
    />
    <Route
      path=":id"
      component={WalletTabbedViewContainer}
      authorize={[Constants.B2B_MASTER_USER, Constants.B2B_BRANCH_USER]}
    />
    <Route
      path="deposit"
      component={WalletTabbedViewContainer}
      authorize={[Constants.B2B_MASTER_USER, Constants.B2B_BRANCH_USER]}
    />
  </Route>
)

const BillingHistoryRoute = store => (
  <Route
    path="billing-history"
    component={LoadableTransactionHistory}
    onEnter={prepaidRoutesHandler(store)}
  />
)

const VoucherRoute = store => (
  <Route
    path="voucher"
    component={LoadableVoucher}
    onEnter={prepaidRoutesHandler(store)}
  />
)

const PaymentMethodRoute = store => (
  <Route
    path="payment-method"
    component={LoadablePaymentMethod}
    onEnter={prepaidRoutesHandler(store)}
  />
)

export {
  RevampVanBookingRoutes,
  MonthlySettlementWalletRoutes,
  BillingHistoryRoute,
  VoucherRoute,
  PaymentMethodRoute,
}
