const getFeatureFlagPrepaidTopupBankTransferOnly = state =>
  state.featureFlagPrepaidTopupBankTransferOnly
const getCredit = ({ creditBalance = {} }) => creditBalance.credit
const getFreeCredit = ({ creditBalance = {} }) => creditBalance.freeCredit
const getTotalCredit = ({ creditBalance = {} }) =>
  Number(creditBalance.credit) + Number(creditBalance.freeCredit)
const getFreeCreditExpiryDate = ({ creditBalance = {} }) =>
  creditBalance.freeCreditExpiryDate
const getUnsettledBalance = ({ creditBalance = {} }) =>
  creditBalance.unsettledBalance

export {
  getFeatureFlagPrepaidTopupBankTransferOnly,
  getCredit,
  getFreeCredit,
  getTotalCredit,
  getFreeCreditExpiryDate,
  getUnsettledBalance,
}
