import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

import {
  clearMessage,
  clearMessages,
  getNotifications,
  readMessage,
  notificationCancelOrderRequest,
} from '../../legacy/actions/notification'
import Actions from '../../legacy/actions/order_requests'
import { toggleNotificationCentre } from '../../legacy/actions/header'
import {
  getIsLoadingAction,
  getNotificationMessages,
  getOrderRequests,
  getOrderRequestsIncluded,
} from '../duck/selectors'
import { Message } from './message/message.component'
import { MergeMessage } from './merge-message/merge-message.component'
import { PopupNotificationsContainer, PopupNotificationsList } from './styles'
import { getOrderRequestStatus } from '../../legacy/views/helpers'

import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

const PopupNotifications = withRouter(
  ({ isNavigationCollapsed, isNavigationHovered, router }) => {
    const dispatch = useDispatch()
    const messages = useSelector(state => getNotificationMessages(state))
    const orderRequests = useSelector(state => getOrderRequests(state))
    const isLoadingAction = useSelector(state => getIsLoadingAction(state))
    const included = useSelector(state => getOrderRequestsIncluded(state))
    const onDismissAllClick = () => {
      dispatch(clearMessages())
    }
    const onOrderIdClick = (orderRequestEventId, id, includedArg, payload) => {
      dispatch(clearMessage(orderRequestEventId || payload.deliveryOrderUuid))
      dispatch(readMessage(orderRequestEventId || payload.deliveryOrderUuid))
      if (id) {
        dispatch(Actions.selectOrderRequest(id.toString(), includedArg))
        dispatch(Actions.toggleOpenDetail())
        router.push(`/orders/${id}`)
      } else if (payload.deliveryOrderUuid && payload.deliveryOrderTid) {
        dispatch(
          push({
            pathname: '/delivery/order-management',
            state: {
              uuid: payload.deliveryOrderUuid,
              tid: payload.deliveryOrderTid,
            },
          })
        )
      }
    }
    const onDismissClick = orderRequestEventId => {
      dispatch(clearMessage(orderRequestEventId))
      dispatch(readMessage(orderRequestEventId))
    }
    const onSeeAllClick = () => {
      dispatch(clearMessages())
      dispatch(getNotifications(1, 8))
      dispatch(toggleNotificationCentre())
    }
    const onCancelOrderClick = orderRequestId => {
      dispatch(notificationCancelOrderRequest(orderRequestId))
    }

    const messageCount = messages.length
    const limitMessages = messageCount < 3 ? messages : messages.slice(0, 2)

    const popupMessages = limitMessages.reverse().map((message, i) => {
      const orderRequestStatus = getOrderRequestStatus(
        String(message.orderRequestId),
        orderRequests
      )
      return (
        <Message
          key={i}
          listKey={i}
          message={message}
          isLoadingAction={isLoadingAction}
          orderRequestStatus={orderRequestStatus}
          onOrderIdClick={() =>
            onOrderIdClick(
              message.orderRequestEventId,
              message.orderRequestId,
              included,
              message
            )
          }
          onDismissClick={() => onDismissClick(message.orderRequestEventId)}
          onCancelOrderClick={() => onCancelOrderClick(message.orderRequestId)}
        />
      )
    })

    let mergedMessages
    if (messageCount > 2) {
      mergedMessages = (
        <MergeMessage
          key="popup-merge"
          messageCount={messageCount}
          onDismissAllClick={() => onDismissAllClick(messages)}
          onSeeAllClick={() => onSeeAllClick()}
        />
      )
    }

    return (
      <PopupNotificationsContainer
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <PopupNotificationsList>
          {popupMessages}
          {mergedMessages}
        </PopupNotificationsList>
      </PopupNotificationsContainer>
    )
  }
)

PopupNotifications.propTypes = {
  isNavigationCollapsed: PropTypes.bool,
  isNavigationHovered: PropTypes.bool,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
}

export { PopupNotifications }
