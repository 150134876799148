import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { useSelector } from 'react-redux'

import { DEFAULT_FONT_STACK_FOR_JS } from 'ggx-componentlibrary/design/typography/typography.constants'
import { Message } from '../../popup-notifications/message/message.component'
import {
  LoadingSpinner,
  getOrderRequestStatus,
} from '../../../legacy/views/helpers'
import { eventsWithoutNotification } from '../../../legacy/constants/notification'
import translate from '../../../legacy/locales/translate'
import Color from '../../../legacy/views/helpers/color'

import {
  NotificationContent,
  Notifications,
  LoadMore,
} from './messages-centre.component.styles'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

const translateWithPrefix = key =>
  translate(`notificationCentre.messagesCentre.${key}`)

const MessagesCentre = ({
  messages,
  currentPage,
  isLoading,
  isLoadingAction,
  onLoadMoreClick,
  onOrderIdClick,
  onCancelOrderClick,
  orderRequests,
  included,
}) => {
  const isNotification = message =>
    !eventsWithoutNotification.includes(message.eventType)
  const filteredMessages = messages.filter(isNotification)

  const notificationMessages = filteredMessages.map((message, i) => {
    const orderRequestStatus = getOrderRequestStatus(
      String(message.payload.orderRequestId),
      orderRequests
    )

    return (
      <Message
        key={i}
        listKey={i}
        message={message.payload}
        orderRequestStatus={orderRequestStatus}
        onOrderIdClick={() => {
          onOrderIdClick(
            message.payload.orderRequestEventId,
            message.payload.orderRequestId,
            included,
            message.payload
          )
        }}
        onCancelOrderClick={() =>
          onCancelOrderClick(message.payload.orderRequestId)
        }
        isLoadingAction={isLoadingAction}
      />
    )
  })

  const _renderLoadMoreButton = () => {
    if (isLoading) {
      return <LoadingSpinner style={{ marginTop: '7px' }} />
    }
    return (
      <Button
        sx={{
          fontWeight: 'bold',
          lineHeight: '15px',
          fontSize: '12px',
          color: Color.LOCHMARA_BLUE,
          fontFamily: DEFAULT_FONT_STACK_FOR_JS,
          '&:hover': {
            color: Color.BAHAMA_BLUE,
          },
        }}
        onClick={() => onLoadMoreClick(currentPage + 1, 8)}
      >
        {translateWithPrefix('loadMore')}
      </Button>
    )
  }

  return (
    <NotificationContent>
      <Notifications>{notificationMessages}</Notifications>
      <LoadMore>{_renderLoadMoreButton()}</LoadMore>
    </NotificationContent>
  )
}

MessagesCentre.propTypes = {
  onLoadMoreClick: PropTypes.func.isRequired,
  onOrderIdClick: PropTypes.func.isRequired,
  onCancelOrderClick: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object),
  orderRequests: PropTypes.arrayOf(PropTypes.object).isRequired,
  included: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPage: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingAction: PropTypes.bool.isRequired,
}

export default MessagesCentre
