import styled from 'styled-components'

import {
  COLLAPSED_WIDTH as COLLAPSED_WIDTH_V2,
  EXPANDED_WIDTH as EXPANDED_WIDTH_V2,
} from 'ggx-global/navigation/v2/navigation.constants'
import { SPACING } from 'ggx-componentlibrary/design/spacing/spacing.constants'
import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'

export const PopupNotificationsContainer = styled.span`
  font-size: 14px;
  height: 0;
  > ul {
    left: ${props => {
      const collapsedWidth = COLLAPSED_WIDTH_V2
      const expandedWidth = EXPANDED_WIDTH_V2

      if (props.$isNavigationHovered || !props.$isNavigationCollapsed) {
        return `calc(${expandedWidth} + ${SPACING.XS})`
      }
      return `calc(${collapsedWidth} + ${SPACING.XS})`
    }};
  }
`

export const PopupNotificationsList = styled.ul`
  width: 22rem;
  padding: 0;
  color: ${COLOR.GREY_DARKER};
  background-color: ${COLOR.GREY_LIGHTEST};
  box-shadow: 0 1.357em 1.786em 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
  position: fixed;
  bottom: 0;
  liststyletype: none;
`
