import styled from "styled-components"

import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { Loader } from "ggx-componentlibrary/components/loader/loader.component"
import { SmallTextBold } from "ggx-componentlibrary/design/typography/typography.styles"
import { Button } from "ggx-componentlibrary/components/button/button.component"
import OrderDetailCross from "./assets/order-detail-cross.svg"
import BET_3668 from 'ggx-src/feat/BET_3668'

const ORDER_DETAIL_WIDTH = 370

const StyledOrderDetailCross = styled(OrderDetailCross)`
  cursor: pointer;
  background: none;
  -webkit-appearance: none;
`

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const StaticTrackerMap = styled.img`
  position: relative;
  margin-bottom: ${SPACING.M};
`

const MapContainer = styled.div`
  display: flex;
`

const LoadingMessageWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 161px;
  height: ${SPACING.L};
  align-items: center;
  z-index: 1;
  background-color: ${COLOR.WHITE};
  transform: translateX(79px) translateY(70px);
  border-radius: ${SPACING.XS};
  padding: ${SPACING.XS} 0px;
  box-shadow: 0px ${SPACING.XXS} ${SPACING.XXS} rgba(0, 0, 0, 0.1);

  ${Loader} {
    width: 18px;
    height: 18px;
    margin-left: ${SPACING.S};
  }
  ${SmallTextBold} {
    padding-left: ${SPACING.XS};
    color: ${COLOR.BLACK};
    margin-bottom: 0px;
  }
`

const BonusButton = styled(Button)`
  margin-bottom: ${SPACING.M};
`

const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.M};
  ${BET_3668.adjustment.containerStyle}
`

const StyledHR = styled.hr`
  border: none;
  border-top: 1px solid ${COLOR.GREY_LIGHT};
  margin: 0;
`

const AdaptiveContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => !props.$sm ? '1fr 1fr': '1fr'};
  gap: 32px;
`

const TipPanel = styled.div`
  padding: 12px 16px;
  background-color: ${COLOR.GREY_LIGHTEST};
  width: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`

export {
  ORDER_DETAIL_WIDTH,
  StyledOrderDetailCross,
  LoaderWrapper,
  StaticTrackerMap,
  MapContainer,
  LoadingMessageWrapper,
  BonusButton,
  StyledContainer,
  StyledHR,
  AdaptiveContainer,
  TipPanel,
}
