import Route from 'react-router/lib/Route'

import { selfRegistrationService } from '../service/api/self-registration.service'
import { loadableCreator } from '../delivery/loading/loadable-creator'
import { api } from '../legacy/utils/api'
import { featureFlagService } from './../service/feature-flag/feature-flag'

const LoadableSelfRegistration = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'self-registration' */ './self-registration.component.js'
    ),
  componentName: 'ConnectedSelfRegistration',
})

const LoadableAccountCreation = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'account-creation' */ './account-creation/account-creation.component'
    ),
  componentName: 'ConnectedRouteredAccountCreation',
})

const LoadableAccountPassword = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'account-password' */ './account-password/account-password.component'
    ),
  componentName: 'ConnectedAccountPassword',
})

const activateOrganisationAccount = async (nextState, replace, callback) => {
  const { token, email } = nextState.location.query
  let pathname = '/login'
  let state = { trackingMessage: 'email_verification' }

  try {
    await selfRegistrationService.activateOrganisationAccount({ token, email })
    state = { ...state, message: 'account_activated', email }
  } catch (error) {
    if (error.response.status === 400) {
      state = { ...state, error: error.response.data.errors[0], email }
    } else {
      pathname = '/404'
    }
  }

  replace({
    pathname,
    state,
  })
  return callback()
}

const redirectToIndex = store => (_nextState, replace, callback) => {
  if (api.loggedIn()) {
    replace('/')
    return callback()
  }

  const checkFlag = () => {
    const state = store.getState()
    const shouldRedirect = state.featureFlagSelfRegistration === false

    if (shouldRedirect) {
      replace('/login')
    }
    callback()
  }

  Promise.all([
    featureFlagService.triggerFeatureFlag('self_registration'),
    featureFlagService.triggerFeatureFlag('login_mobile_view'),
  ]).finally(checkFlag)

  featureFlagService?.setIdentifier()
}

const onEnterSetPassword = async (nextState, replace, callback) => {
  const { token } = nextState.location.query

  if (!token) {
    replace('/login')
  }

  return callback()
}

const SelfRegistrationRoutes = store => (
  <Route>
    <Route
      path="signup"
      component={LoadableAccountCreation}
      onEnter={redirectToIndex(store)}
    />
    <Route
      path="activate-organisation-account"
      onEnter={activateOrganisationAccount}
    />
    <Route component={LoadableSelfRegistration}>
      <Route
        path="set-password"
        component={LoadableAccountPassword}
        onEnter={onEnterSetPassword}
      />
    </Route>
  </Route>
)

export { SelfRegistrationRoutes }
