const DISPLAY_CUSTOM_SERVICES = 'DISPLAY_CUSTOM_SERVICES'
const DISPLAY_ORDER_QUOTATION = 'DISPLAY_ORDER_QUOTATION'
const BULK_IMPORT_SET_TABLE_DATA = 'BULK_IMPORT_SET_TABLE_DATA'
const BULK_IMPORT_SET_QUOTATION = 'BULK_IMPORT_SET_QUOTATION'
const BULK_IMPORT_EDIT_TABLE_DATA = 'BULK_IMPORT_EDIT_TABLE_DATA'
const BULK_IMPORT_DELETE_TABLE_DATA = 'BULK_IMPORT_DELETE_TABLE_DATA'
const BULK_IMPORT_DELETE_TABLE_ROW = 'BULK_IMPORT_DELETE_TABLE_ROW'
const BULK_IMPORT_SET_QUOTE_IN_PROGRESS = 'BULK_IMPORT_SET_QUOTE_IN_PROGRESS'

export {
  DISPLAY_CUSTOM_SERVICES,
  DISPLAY_ORDER_QUOTATION,
  BULK_IMPORT_SET_TABLE_DATA,
  BULK_IMPORT_SET_QUOTATION,
  BULK_IMPORT_DELETE_TABLE_DATA,
  BULK_IMPORT_EDIT_TABLE_DATA,
  BULK_IMPORT_SET_QUOTE_IN_PROGRESS,
  BULK_IMPORT_DELETE_TABLE_ROW,
}
