import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledSmallText } from 'ggx-src/order-management/order-detail-2/order-status/order-status.component.styles'

export default {
  HideUpdateLink: ({ show, onClick }) => {
    const [t] = useTranslation()
    return show ? (
      <StyledSmallText onClick={onClick}>{t('hide_updates')}</StyledSmallText>
    ) : null
  },
}
