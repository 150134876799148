import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import IconExternalLink from 'ggx-src/assets/external_link.svg'

import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'

import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

import CopySVG from '../assets/copy.svg'
import {
  TraceAndTrackWapper,
  OpenAnTraceLink,
  OpenAnTraceLinkText,
  CopyAnTraceContainer,
} from './order-sharing.component.styles'
import SendOrderReceiptModal from './send-order-receipt-modal.component'
import OrderSharingMenu from './order-sharing-menu.component'

const OrderSharing = ({
  uuid,
  status,
  whatsappURL,
  traceURL,
  trackingOpenLink,
  handleCopyLink,
  sendOrderReceipt,
}) => {
  const [t] = useTranslation()
  const [showSendOrderReceiptModal, setShowSendOrderReceiptModal] = useState(
    false
  )

  const handleOpenSendOrderReceiptModal = useCallback(() => {
    setShowSendOrderReceiptModal(true)
  }, [])

  const handleCancelSendOrderReceipt = useCallback(() => {
    setShowSendOrderReceiptModal(false)
  }, [])

  return (
    <TraceAndTrackWapper>
      <OpenAnTraceLink
        target="blank"
        href={traceURL}
        onClick={() => {
          trackingOpenLink &&
            trackingOpenLink({
              url: traceURL,
              urlName: 'Track Order',
            })
        }}
      >
        <OpenAnTraceLinkText>{t('order_tracking_link')}</OpenAnTraceLinkText>
        <IconExternalLink />
      </OpenAnTraceLink>
      <OrderSharingMenu
        handleCopyLink={handleCopyLink}
        handleOpenSendOrderReceiptModal={handleOpenSendOrderReceiptModal}
        whatsappURL={whatsappURL}
        status={status}
      />
      {showSendOrderReceiptModal && (
        <SendOrderReceiptModal
          uuid={uuid}
          handleCancelSendOrderReceipt={handleCancelSendOrderReceipt}
          sendOrderReceipt={sendOrderReceipt}
        />
      )}
    </TraceAndTrackWapper>
  )
}

OrderSharing.propTypes = {
  uuid: PropTypes.string,
  status: PropTypes.string,
  whatsappURL: PropTypes.string,
  traceURL: PropTypes.string,
  trackingOpenLink: PropTypes.func,
  handleCopyLink: PropTypes.func,
  sendOrderReceipt: PropTypes.func,
}

export default OrderSharing
