import Route from 'react-router/lib/Route'
import IndexRedirect from 'react-router/lib/IndexRedirect'

import Actions from '../legacy/actions/session'
import { featureFlagService } from './../service/feature-flag/feature-flag'
import { loadableCreator } from './loading/loadable-creator'
import { getFeatureFlagDeveloper } from '../developer/duck/selectors'
import { payByMonthlySettlement } from '../van-booking/duck/selectors'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

const LoadableOrder = loadableCreator({
  dynamicImport: () =>
    import(/* webpackChunkName: 'delivery-order' */ './order/order.component'),
  componentName: 'ConnectedOrder',
})

const LoadableOrderManagement = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'delivery-mgmt' */ './order-management/order-management.component'
    ),
  componentName: 'ConnectedOrderManagementWithTranslation',
})

const LoadablePrintLabels = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'delivery-labels' */ './order-management/print-labels/print-labels.component'
    ),
  componentName: 'PrintLabels',
})

const LoadableBulkImport = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'delivery-bulk' */ './bulk-import/bulk-import.component'
    ),
  componentName: 'ConnectedBulkImport',
})

const LoadableBulkImportComplete = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'delivery-bulk-complete' */ './bulk-import/bulk-import-complete/bulk-import-complete.component'
    ),
  componentName: 'BulkImportComplete',
})

const LoadableEcommerceIntegration = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'delivery-ecommerce-integration' */ './ecommerce-integration/ecommerce-integration.component'
    ),
  componentName: 'EcommerceIntegration',
})

const LoadableShopifyIntegration = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'delivery-shopify-integration' */ './shopify-integration/shopify-integration.component'
    ),
  componentName: 'ShopifyIntegration',
})

const redirectToIndex = (store, featureFlagName) => (
  _nextState,
  replace,
  callback
) => {
  const checkFlag = () => {
    const state = store.getState()
    const shouldRedirect = state[featureFlagName] === false

    if (shouldRedirect) {
      replace('/')
    }
    callback()
  }

  featureFlagService
    .triggerFeatureFlag('gogodelivery_ggb')
    .then(checkFlag)
    .catch(checkFlag)
}

const printLabelsRedirectionHandler = () => (nextState, replace, callback) => {
  const {
    location: {
      query: { id = '' },
    },
  } = nextState
  if (id === '') {
    replace('/')
  }
  callback()
}

const bulkImportRedirectionHandler = store => (
  _nextState,
  replace,
  callback
) => {
  const { dispatch } = store
  const checkFlags = () => {
    const state = store.getState()
    const isMonthlySettlement = payByMonthlySettlement(state)

    if (isMonthlySettlement) {
      replace('/delivery/order')
    }
    callback()
  }

  Promise.all([dispatch(Actions.currentUser())])
    .then(checkFlags)
    .catch(checkFlags)
}

const integrationRedirectionHandler = store => (
  _nextState,
  replace,
  callback
) => {
  const checkFlag = () => {
    const state = store.getState()
    const redirectToIntegrationDashboard =
      getFeatureFlag({ state, featureFlag: 'delivery_integration' }) === false

    if (redirectToIntegrationDashboard) {
      replace('/developer/dashboard')
    }
    callback()
  }

  featureFlagService
    .triggerFeatureFlag('delivery_integration')
    .then(checkFlag)
    .catch(checkFlag)
}

const shopifyIntegrationRedirectionHandler = store => (
  _nextState,
  replace,
  callback
) => {
  const checkFlag = () => {
    const state = store.getState()
    const shopifyFeatureFlag = getFeatureFlag({
      state,
      featureFlag: 'shopify_integration',
    })

    if (!shopifyFeatureFlag) {
      replace('/')
    }
    callback()
  }

  featureFlagService
    .triggerFeatureFlag('shopify_integration')
    .then(checkFlag)
    .catch(checkFlag)
}

const PrintLabelsRoute = () => (
  <Route
    path="/delivery/order-management/print-labels"
    component={LoadablePrintLabels}
    onEnter={printLabelsRedirectionHandler()}
  />
)

const DeliveryRoutes = store => (
  <Route path="/delivery">
    <IndexRedirect to="/delivery/order" />
    <Route
      path="order"
      component={LoadableOrder}
      onEnter={redirectToIndex(store, 'featureFlagGogodelivery')}
    />
    <Route
      path="order-management"
      component={props => <LoadableOrderManagement {...props} />}
      onEnter={redirectToIndex(store, 'featureFlagGogodelivery')}
    />
    <Route
      path="bulk-import"
      component={LoadableBulkImport}
      onEnter={bulkImportRedirectionHandler(store)}
    />
    <Route
      path="bulk-import/complete"
      component={LoadableBulkImportComplete}
      onEnter={bulkImportRedirectionHandler(store)}
    />
    <Route
      path="integration"
      component={LoadableEcommerceIntegration}
      onEnter={integrationRedirectionHandler(store)}
    />
    <Route
      path="shopify-integration"
      component={LoadableShopifyIntegration}
      onEnter={shopifyIntegrationRedirectionHandler(store)}
    />
  </Route>
)

export { DeliveryRoutes, PrintLabelsRoute }
