import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { WelcomeModal } from './welcome-modal/welcome-modal.component'
import { ServiceSelectionModal } from './service-selection-modal/service-selection-modal.component'
import { onboardingTrackingService } from './tracking.service'
import { FeatureTour } from './feature-tour/feature-tour.component'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

const MODALS = {
  WELCOME: 'WELCOME',
  SELECTION: 'SELECTION',
  TOUR: 'TOUR',
}

const Onboarding = () => {
  const [modalDisplayed, setModalDisplayed] = useState(MODALS.WELCOME)

  useEffect(() => {
    onboardingTrackingService.onboardingQuestionsLanding()
  }, [])

  const handleTutorialStart = () => {
    setModalDisplayed(MODALS.SELECTION)
    onboardingTrackingService.onboardingQuestionsStarted()
  }

  return (
    <>
      {modalDisplayed === MODALS.WELCOME && (
        <WelcomeModal
          handleModalClose={() => setModalDisplayed(null)}
          handleTutorialStart={handleTutorialStart}
          handleFeatureTourStart={() => setModalDisplayed(MODALS.TOUR)}
        />
      )}

      {modalDisplayed === MODALS.SELECTION && (
        <ServiceSelectionModal
          handleModalClose={() => setModalDisplayed(null)}
          handleFeatureTourStart={() => setModalDisplayed(MODALS.TOUR)}
        />
      )}

      {modalDisplayed === MODALS.TOUR && (
        <FeatureTour handleModalClose={() => setModalDisplayed(null)} />
      )}
    </>
  )
}

export { Onboarding, MODALS }
