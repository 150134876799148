import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

import {
  Body,
  TextLink,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { NotificationLargeText, MessageBody } from './message.component.styles'
import getCountry from 'ggx-src/legacy/views/helpers/get_country'

const MessageText = ({ orderStatus, body, orderRequestId }) => {
  if (getCountry() === 'HK' && orderStatus === 'completed') {
    return (
      <MessageBody>
        <NotificationLargeText>
          <Trans
            i18nKey="notification.completed"
            values={{ link: `#${orderRequestId}` }}
          >
            <TextLink />
          </Trans>
        </NotificationLargeText>
        <Body>
          <Trans
            i18nKey="notification.can.favourite.drivers.anytime"
            values={{
              link: i18next.t('sidemenu.van.item.orderManagement'),
            }}
          >
            <TextLink />
          </Trans>
        </Body>
      </MessageBody>
    )
  }

  return <MessageBody> {body} </MessageBody>
}

MessageText.propTypes = {
  orderStatus: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  orderRequestId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

export default MessageText
