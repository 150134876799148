import Route from 'react-router/lib/Route'
import { useFeatureFlagFromExternalStore } from 'ggx-global/hooks/use-feature-flag'

import { loadableCreator } from '../delivery/loading/loadable-creator'
import Constants from '../legacy/constants/auth'
import useTracking from 'ggx-src/users-and-branches/use-tracking'
import Actions from 'ggx-src/legacy/actions/session'

const LoadableUsersAndBranches = loadableCreator({
  dynamicImport: () =>
    import(/* webpackChunkName: 'users' */ './users-and-branches.component'),
  componentName: 'ConnectedUsersAndBranches',
})

const LoadableUsersAndBranchesV2 = loadableCreator({
  dynamicImport: () =>
    import(/* webpackChunkName: 'users' */ './users-and-branches-v2.component'),
  componentName: 'ConnectedUsersAndBranchesV2',
})

const Noop = () => null

const UsersAndBranchesRoutes = store => {
  const { sendEventUsersAndBranches } = useTracking()
  return (
    <Route
      path="users-and-branches"
      component={LoadableUsersAndBranchesV2}
      onEnter={(_nextState, replace, callback) => {
        requestIdleCallback(sendEventUsersAndBranches)
        store.dispatch(Actions.currentUser()).finally(callback)
      }}
      authorize={[
        Constants.B2B_ADMIN_USER,
        Constants.B2B_MASTER_USER,
        Constants.B2B_BRANCH_USER,
      ]}
    />
  )
}

export { UsersAndBranchesRoutes }
